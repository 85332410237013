<template>
  <div class="body">
    <div class="main">
      <div class="wrap">
        <div class="card">
          <div class="title">学生基本信息</div>
          <div class="box" style="justify-content: space-between">
            <div class="btn btn-1" @click="show1 = true">
              <i class="el-icon-upload2"></i>
              <p>导入</p></div>
            <div class="btn" @click="show2 = true">
              <i class="el-icon-download"></i>
              <p>导出</p></div>
          </div>
        </div>
        <div class="card">
          <div class="title">学生成绩信息</div>
          <div class="box">
            <div class="btn" @click="show3 = true">
              <i class="el-icon-download"></i>
              <p>导出</p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="title">学生分班信息</div>
          <div class="box">
            <div class="btn" @click="show4 = true">
              <i class="el-icon-download"></i>
              <p>导出</p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="title">学生选科信息</div>
          <div class="box">
            <div class="btn" @click="show5 = true">
              <i class="el-icon-download"></i>
              <p>导出</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 学生基本信息导入 -->
    <el-dialog custom-class="t-dialog"
               :visible.sync="show1"
               title="学生基本信息导入"
               :before-close="onClose1"
               :close-on-press-escape="false"
               :close-on-click-modal="false">
      <div class="dia-top">
        <p class="p1">下载导入模板</p>
        <p class="p2">请先下载导入模板，按模板要求填写数据！为避免导入失败，填写过程中请勿修改表头！</p>
        <div class="box">
          <a class="t-btn" href="https://main.pro.honghueducation.com:8003/template/tool_student_class.xlsx">
            <i class="el-icon-download"></i>
            <p>学生行政班模板</p>
          </a>
          <a class="t-btn" style="margin-left: 40rem"
             href="https://main.pro.honghueducation.com:8003/template/tool_live_info.xlsx">
            <i class="el-icon-download"></i>
            <p>学生宿舍分配模板</p>
          </a>
        </div>
      </div>
      <div class="dia-main">
        <p class="p1">导入文件</p>
        <p class="p2">·支持文件格式：xlsx、xls</p>
        <p class="p2">·文件大小不超过10M。</p>
        <div class="form">
          <div class="form-item">
            <span>校区：</span>
            <el-select v-model="school_id"
                       placeholder="请选择校区"
                       @change="getGrade"
                       size="small">
              <el-option
                  v-for="item in school_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="form-item">
            <span>年级：</span>
            <el-select v-model="grade_id" placeholder="请选择年级" size="small">
              <el-option
                  v-for="item in grade_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="form-item">
            <span>学生行政班：</span>
            <el-upload
                class="upload-demo"
                action=""
                :before-remove="onClassRemove"
                :on-change="onClassChange"
                accept=".xlsx,.xls"
                :auto-upload="false"
                multiple
                :limit="1"
                :file-list="class_files">
              <el-button :style="{display: !class_files.length ? 'block' : 'none'}" size="small" type="primary"
                         icon="el-icon-upload2">上传文件
              </el-button>
            </el-upload>
          </div>
          <div class="form-item">
            <span>学生宿舍分配：</span>
            <el-upload
                class="upload-demo"
                action=""
                :before-remove="onRoomRemove"
                :on-change="onRoomChange"
                accept=".xlsx,.xls"
                :auto-upload="false"
                multiple
                :limit="1"
                :file-list="room_files">
              <el-button :style="{display: !room_files.length ? 'block' : 'none'}" size="small" type="primary"
                         icon="el-icon-upload2">上传文件
              </el-button>
            </el-upload>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :disabled="!(school_id && grade_id && (class_files.length || room_files.length))" type="primary"
                   @click="onSubmit1">导 入</el-button>
        <el-button @click="onClose1">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 学生基本信息导出 -->
    <el-dialog custom-class="t-dialog-1004"
               :visible.sync="show2"
               title="学生基本信息导出"
               :before-close="onClose2"
               :close-on-press-escape="false"
               :close-on-click-modal="false">
      <div class="select-box">
        <div class="box-item">
          <span>校区：</span>
          <el-select class="w-160"
                     v-model="school_id_info"
                     placeholder="请选择校区"
                     @change="getGrade"
                     size="small">
            <el-option
                v-for="item in school_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="box-item">
          <span>年级：</span>
          <el-select class="w-160"
                     v-model="grade_id_info"
                     placeholder="请选择年级"
                     @change="getClass"
                     size="small">
            <el-option
                v-for="item in grade_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="box-item">
          <span>行政班：</span>
          <el-select class="w-160" v-model="class_id_info" placeholder="请选择行政班" size="small">
            <el-option
                v-for="item in class_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="main-box">
        <div class="left">
          <div class="flex justify-between">
            <p class="p3">选择导出字段</p>
            <el-checkbox :indeterminate="info_isIndeterminate" v-model="info_checkAll"
                         @change="handleInfoCheckAllChange">全选
            </el-checkbox>
          </div>
          <div>
            <p class="p3 mt-20">基础信息</p>
            <div>
              <el-checkbox-group v-model="info_selected" @change="handleCheckedInfoChange">
                <el-checkbox v-for="item in show_base"
                             :disabled="item === '学生姓名' || item === '学号'"
                             :checked="item === '学生姓名' || item === '学号'"
                             :label="item"
                             :key="item">{{ item }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <p class="p3 mt-20">招生信息</p>
            <div>
              <el-checkbox-group v-model="info_selected" @change="handleCheckedInfoChange">
                <el-checkbox v-for="item in show_base_info" :label="item" :key="item">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="right">
          <p class="p3">选择导出字段</p>
          <p class="p3 mt-20">基础信息</p>
          <p class="p4" v-for="item in info_selected" v-if="show_base.includes(item)">{{ item }}</p>
          <p class="p3 mt-20">招生信息</p>
          <p class="p4" v-for="item in info_selected" v-if="show_base_info.includes(item)">{{ item }}</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary"
                   :disabled="!grade_id_info"
                   @click="onSubmit2">导 出</el-button>
        <el-button @click="onClose2">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 学生成绩信息导出 -->
    <el-dialog custom-class="t-dialog-1004"
               :visible.sync="show3"
               title="学生成绩信息导出"
               :before-close="onClose3"
               :close-on-press-escape="false"
               :close-on-click-modal="false">
      <div class="select-box">
        <div class="box-item">
          <span>考试：</span>
          <el-select class="w-160"
                     v-model="exam_id_grade"
                     @change="examChange"
                     placeholder="请选择考试" size="small">
            <el-option
                v-for="item in exam_options"
                :key="item.exam_id"
                :label="item.exam_name"
                :value="item.exam_id">
            </el-option>
          </el-select>
        </div>
        <div class="box-item">
          <span>校区：</span>
          <el-select class="w-160" v-model="school_id_grade" placeholder="请选择校区" size="small">
            <el-option
                v-for="item in school_options1"
                :key="item.school_id"
                :label="item.school_name"
                :value="item.school_id">
            </el-option>
          </el-select>
        </div>
        <div class="box-item">
          <span>年级：</span>
          <el-select class="w-160" v-model="grade_id_grade" placeholder="请选择年级" size="small">
            <el-option
                v-for="item in grade_options1"
                :key="item.grade_id"
                :label="item.grade_name"
                :value="item.grade_id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="main-box">
        <div class="left">
          <div class="flex justify-between">
            <p class="p3">选择导出字段</p>
            <el-checkbox :indeterminate="grade_isIndeterminate" v-model="grade_checkAll"
                         @change="handleGradeCheckAllChange">全选
            </el-checkbox>
          </div>
          <div>
            <p class="p3 mt-20">学生信息</p>
            <div>
              <el-checkbox-group v-model="grade_selected" @change="handleCheckedGradeChange">
                <el-checkbox v-for="item in show_exam_student"
                             :disabled="item === '学生姓名' || item === '学号'"
                             :checked="item === '学生姓名' || item === '学号'"
                             :label="item"
                             :key="item">{{ item }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <p class="p3 mt-20">考试信息</p>
            <div>
              <el-checkbox-group v-model="grade_selected" @change="handleCheckedGradeChange">
                <el-checkbox v-for="item in show_exam" :label="item" :key="item">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="right">
          <p class="p3">当前选定的字段</p>
          <p class="p3 mt-20">学生信息</p>
          <p class="p4" v-for="item in grade_selected" v-if="show_exam_student.includes(item)">{{ item }}</p>
          <p class="p3 mt-20">考试信息</p>
          <p class="p4" v-for="item in grade_selected" v-if="show_exam.includes(item)">{{ item }}</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary"
                   :disabled="!grade_id_grade"
                   @click="onSubmit3">导 出</el-button>
        <el-button @click="onClose3">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 学生分班信息导出 -->
    <el-dialog custom-class="t-dialog-1004"
               :visible.sync="show4"
               title="学生分班信息导出"
               :before-close="onClose4"
               :close-on-press-escape="false"
               :close-on-click-modal="false">
      <div class="select-box">
        <div class="box-item">
          <span>校区：</span>
          <el-select class="w-160"
                     v-model="school_id_class"
                     placeholder="请选择校区"
                     @change="getGrade"
                     size="small">
            <el-option
                v-for="item in school_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="box-item">
          <span>年级：</span>
          <el-select class="w-160"
                     v-model="grade_id_class"
                     placeholder="请选择年级"
                     @change="getClass"
                     size="small">
            <el-option
                v-for="item in grade_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="box-item">
          <span>行政班：</span>
          <el-select class="w-160" v-model="class_id_class" placeholder="请选择行政班" size="small">
            <el-option
                v-for="item in class_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="main-box">
        <div class="left">
          <div class="flex justify-between">
            <p class="p3">选择导出字段</p>
            <el-checkbox :indeterminate="class_isIndeterminate" v-model="class_checkAll"
                         @change="handleClassCheckAllChange">全选
            </el-checkbox>
          </div>
          <div>
            <p class="p3 mt-20">学生信息</p>
            <div>
              <el-checkbox-group v-model="class_selected" @change="handleCheckedClassChange">
                <el-checkbox v-for="item in show_banding"
                             :disabled="item === '学生姓名' || item === '学号'"
                             :checked="item === '学生姓名' || item === '学号'"
                             :label="item"
                             :key="item">{{ item }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="right">
          <p class="p3">当前选定的字段</p>
          <p class="p3 mt-20">学生信息</p>
          <p class="p4" v-for="item in class_selected" v-if="show_banding.includes(item)">{{ item }}</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary"
                   :disabled="!grade_id_class"
                   @click="onSubmit4">导 出</el-button>
        <el-button @click="onClose4">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 学生选科信息导出 -->
    <el-dialog custom-class="t-dialog-1004"
               :visible.sync="show5"
               title="学生选科信息导出"
               :before-close="onClose5"
               :close-on-press-escape="false"
               :close-on-click-modal="false">
      <div class="select-box">
        <div class="box-item">
          <span>校区：</span>
          <el-select class="w-160"
                     v-model="school_id_subject"
                     placeholder="请选择校区"
                     @change="getGrade"
                     size="small">
            <el-option
                v-for="item in school_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="box-item">
          <span>年级：</span>
          <el-select class="w-160"
                     v-model="grade_id_subject"
                     placeholder="请选择年级"
                     @change="getClass"
                     size="small">
            <el-option
                v-for="item in grade_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="box-item">
          <span>行政班：</span>
          <el-select class="w-160" v-model="class_id_subject" placeholder="请选择行政班" size="small">
            <el-option
                v-for="item in class_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="main-box">
        <div class="left">
          <div class="flex justify-between">
            <p class="p3">选择导出字段</p>
            <el-checkbox :indeterminate="subject_isIndeterminate" v-model="subject_checkAll"
                         @change="handleSubjectCheckAllChange">全选
            </el-checkbox>
          </div>
          <div>
            <p class="p3 mt-20">学生信息</p>
            <div>
              <el-checkbox-group v-model="subject_selected" @change="handleCheckedSubjectChange">
                <el-checkbox v-for="item in show_optional"
                             :disabled="item === '学生姓名' || item === '学号'"
                             :checked="item === '学生姓名' || item === '学号'"
                             :label="item"
                             :key="item">{{ item }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <p class="p3 mt-20">科目信息</p>
            <div>
              <el-checkbox-group v-model="subject_selected" @change="handleCheckedSubjectChange">
                <el-checkbox v-for="item in show_optional_subject"
                             :label="item"
                             :key="item">{{ item }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="right">
          <p class="p3">当前选定的字段</p>
          <p class="p3 mt-20">学生信息</p>
          <p class="p4" v-for="item in subject_selected" v-if="show_optional.includes(item)">{{ item }}</p>
          <p class="p3 mt-20">科目信息</p>
          <p class="p4" v-for="item in subject_selected" v-if="show_optional_subject.includes(item)">{{ item }}</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary"
                   :disabled="!grade_id_subject"
                   @click="onSubmit5">导 出</el-button>
        <el-button @click="onClose5">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"index","meta":{"title":"列表"}}},
  name: "Index",
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,

      show_base: [],  //学生基本信息-基础
      show_base_info: [],  //学生基本信息-招生
      show_exam_student: [],  //学生成绩信息-学生
      show_exam: [],  //学生成绩信息-考试
      show_banding: [],  //学生分班信息
      show_optional: [],  //学生选科信息-学生
      show_optional_subject: [],  //学生选科信息-科目

      //校区、年级、行政班配置
      school_options: [],
      grade_options: [],
      class_options: [{label: '全部', value: 0}],

      //考试、校区、年级配置
      exam_options: [],
      school_options1: [],
      grade_options1: [],

      school_id: '',
      grade_id: '',
      class_files: [],
      room_files: [],

      school_id_info: '',
      grade_id_info: '',
      class_id_info: 0,
      info_selected: [],
      info_checkAll: false,
      info_isIndeterminate: true,

      exam_id_grade: '',
      school_id_grade: '',
      grade_id_grade: '',
      grade_selected: [],
      grade_checkAll: false,
      grade_isIndeterminate: true,

      school_id_class: '',
      grade_id_class: '',
      class_id_class: 0,
      class_selected: [],
      class_checkAll: false,
      class_isIndeterminate: true,

      school_id_subject: '',
      grade_id_subject: '',
      class_id_subject: 0,
      subject_selected: [],
      subject_checkAll: false,
      subject_isIndeterminate: true,
    }
  },
  created() {
    this.getConfig()

    this.getSearchConfig()

    this.getSchool()
  },
  methods: {
    getConfig() {
      this.$_axios2('api/tool/student/student-conf').then(res => {
        this.show_base = res.data.data.show_base
        this.show_base_info = res.data.data.show_base_info
        this.show_exam_student = res.data.data.show_exam_student
        this.show_exam = res.data.data.show_exam
        this.show_banding = res.data.data.show_banding
        this.show_optional = res.data.data.show_optional
        this.show_optional_subject = res.data.data.show_optional_subject
      })
    },

    //获取考试配置
    getSearchConfig() {
      this.$_axios.post('/site/get-query-cond').then(res => {
        this.exam_options = res.data.data
      })
    },

    examChange(e) {
      this.schoolOptions = []
      this.gradeOptions = []
      this.school_id_grade = ''
      this.grade_id_grade = ''
      for (let i = 0; i < this.exam_options.length; i++) {
        if (e == this.exam_options[i].exam_id) {
          this.school_options1 = this.exam_options[i].school_data
          this.grade_options1 = this.exam_options[i].grade_data
          return;
        }
      }
    },

    getSchool() {
      this.$_axios2('/api/common/screen/school').then(res => {
        this.school_options = res.data.data.map(item => ({
          value: item.id,
          label: item.school_name
        }))
      })
    },

    getGrade() {
      this.grade_id = ''
      this.grade_id_info = ''
      this.class_id_info = 0
      this.grade_id_class = ''
      this.class_id_class = 0
      this.grade_id_subject = ''
      this.class_id_subject = 0
      this.$_axios2('/api/common/screen/grade').then(res => {
        this.grade_options = res.data.data.map(item => ({
          value: item.id,
          label: item.grade_name
        }))
      })
    },

    getClass() {
      this.class_id_info = 0
      this.class_id_class = 0
      this.class_id_subject = 0
      this.class_options = [{label: '全部', value: 0}]
      this.$_axios2('/api/common/screen/admin-class', {
        params: {
          school_id: this.school_id_info || this.school_id_class || this.school_id_subject,
          grade_id: this.grade_id_info || this.grade_id_class || this.grade_id_subject,
        }
      }).then(res => {
        let arr = res.data.data.map(item => ({
          value: item.id,
          label: item.class_name
        }))
        this.class_options = this.class_options.concat(arr)
      })
    },

    onClassRemove() {
      this.class_files = []
    },

    onClassChange(file, fileList) {
      this.class_files = fileList
    },

    onRoomRemove() {
      this.room_files = []
    },

    onRoomChange(file, fileList) {
      this.room_files = fileList
    },

    onSubmit1() {
      let params = {
        school_id: this.school_id,
        grade_id: this.grade_id,
        class_file: this.class_files.length ? this.class_files[0].raw : '',
        live_file: this.room_files.length ? this.room_files[0].raw : '',
      }
      let formData = new FormData();
      Object.keys(params).forEach(key => {
        formData.append(key, params[key]);
      })
      this.$_axios2.post('api/tool/student/import', formData).then(res => {
        if (res.data.status === 0) {
          this.school_id = ''
          this.grade_id = ''
          this.class_file = []
          this.live_file = []
          this.onClassRemove()
          this.onRoomRemove()
          this.show1 = false
          this.$message.success({
            message: res.data.msg,
          })
        } else {
          // this.$message.error({
          //   message: res.data.data[0],
          // })
        }
      })
    },

    onClose1(done = '') {
      this.show1 = false
      if (!this.school_id) return;
      this.$confirm('是否确认退出', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.school_id = ''
        this.grade_id = ''
        this.class_files = []
        this.room_files = []
        if (done) done()
      }).catch((e) => {
        if (e === 'cancel') this.show1 = true
      })
    },

    onSubmit2() {
      let params = {
        school_id: this.school_id_info,
        grade_id: this.grade_id_info,
        class_id: this.class_id_info,
        selections: JSON.stringify(this.info_selected),
      }
      this.$_axios2.post('api/tool/student/base', params).then(res => {
        if (typeof res.data === 'string') {
          this.$tools.download('学生基本信息', res.data)
        }
      })
    },

    onClose2(done = '') {
      this.show2 = false
      if (!this.school_id_info && this.info_selected.length <= 2) return;
      this.$confirm('是否确认退出', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.school_id_info = ''
        this.grade_id_info = ''
        this.class_id_info = 0
        this.grade_options = []
        this.class_options = [{label: '全部', value: 0}]
        this.info_selected = ['学生姓名', '学号']
        this.info_checkAll = false
        this.info_isIndeterminate = true
        if (done) done()
      }).catch((e) => {
        if (e === 'cancel') this.show2 = true
      })
    },

    onSubmit3() {
      let params = {
        exam_id: this.exam_id_grade,
        school_id: this.school_id_grade,
        grade_id: this.grade_id_grade,
        selections: JSON.stringify(this.grade_selected),
      }
      this.$_axios2.post('api/tool/student/exam', params).then(res => {
        if (typeof res.data === 'string') {
          this.$tools.download('学生成绩信息', res.data)
        }
      })
    },

    onClose3(done = '') {
      this.show3 = false
      if (!this.school_id_grade && !this.exam_id_grade && this.grade_selected.length <= 2) return;
      this.$confirm('是否确认退出', '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        closeOnClickModal: false
      }).then(() => {
        this.exam_id_grade = ''
        this.school_id_grade = ''
        this.grade_id_grade = ''
        this.school_options1 = []
        this.grade_options1 = []
        this.grade_selected = ['学生姓名', '学号']
        this.grade_checkAll = false
        this.grade_isIndeterminate = true
        if (done) done()
      }).catch((e) => {
        if (e === 'cancel') this.show3 = true
      })
    },

    onSubmit4() {
      let params = {
        school_id: this.school_id_class,
        grade_id: this.grade_id_class,
        class_id: this.class_id_class,
        selections: JSON.stringify(this.class_selected),
      }
      this.$_axios2.post('api/tool/student/banding', params).then(res => {
        if (typeof res.data === 'string') {
          this.$tools.download('学生分班信息', res.data)
        }
      })
    },

    onClose4(done = '') {
      this.show4 = false
      if (!this.school_id_class && this.class_selected.length <= 2) return;
      this.$confirm('是否确认退出', '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        closeOnClickModal: false
      }).then(() => {
        this.school_id_class = ''
        this.grade_id_class = ''
        this.class_id_class = 0
        this.grade_options = []
        this.class_options = [{label: '全部', value: 0}]
        this.class_selected = ['学生姓名', '学号']
        this.class_checkAll = false
        this.class_isIndeterminate = true
        if (done) done()
      }).catch((e) => {
        if (e === 'cancel') this.show4 = true
      })
    },

    onSubmit5() {
      let params = {
        school_id: this.school_id_subject,
        grade_id: this.grade_id_subject,
        class_id: this.class_id_subject,
        selections: JSON.stringify(this.subject_selected),
      }
      this.$_axios2.post('api/tool/student/optional', params).then(res => {
        if (typeof res.data === 'string') {
          this.$tools.download('学生选科信息', res.data)
        }
      })
    },

    onClose5(done = '') {
      this.show5 = false
      if (!this.school_id_subject && this.subject_selected.length <= 2) return;
      this.$confirm('是否确认退出', '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        closeOnClickModal: false
      }).then(() => {
        this.school_id_subject = ''
        this.grade_id_subject = ''
        this.class_id_subject = 0
        this.grade_options = []
        this.class_options = [{label: '全部', value: 0}]
        this.subject_selected = ['学生姓名', '学号']
        this.subject_checkAll = false
        this.subject_isIndeterminate = true
        if (done) done()
      }).catch((e) => {
        if (e === 'cancel') this.show5 = true
      })
    },

    handleInfoCheckAllChange(val) {
      this.info_selected = val ? this.show_base.concat(this.show_base_info) : ['学生姓名', '学号'];
      this.info_isIndeterminate = this.info_selected.length === 2;
    },
    handleCheckedInfoChange(value) {
      let checkedCount = value.length;
      let max_length = this.show_base.concat(this.show_base_info).length
      this.info_checkAll = checkedCount === max_length;
      this.info_isIndeterminate = checkedCount > 0 && checkedCount < max_length;
    },

    handleGradeCheckAllChange(val) {
      this.grade_selected = val ? this.show_exam_student.concat(this.show_exam) : ['学生姓名', '学号'];
      this.grade_isIndeterminate = this.grade_selected.length === 2;
    },
    handleCheckedGradeChange(value) {
      let checkedCount = value.length;
      let max_length = this.show_exam_student.concat(this.show_exam).length
      this.grade_checkAll = checkedCount === max_length;
      this.grade_isIndeterminate = checkedCount > 0 && checkedCount < max_length;
    },

    handleClassCheckAllChange(val) {
      this.class_selected = val ? this.show_banding : ['学生姓名', '学号'];
      this.class_isIndeterminate = this.class_selected.length === 2;
    },
    handleCheckedClassChange(value) {
      let checkedCount = value.length;
      let max_length = this.show_banding.length
      this.class_checkAll = checkedCount === max_length;
      this.class_isIndeterminate = checkedCount > 0 && checkedCount < max_length;
    },

    handleSubjectCheckAllChange(val) {
      this.subject_selected = val ? this.show_optional.concat(this.show_optional_subject) : ['学生姓名', '学号'];
      this.subject_isIndeterminate = this.subject_selected.length === 2;
    },
    handleCheckedSubjectChange(value) {
      let checkedCount = value.length;
      let max_length = this.show_optional.concat(this.show_optional_subject).length
      this.subject_checkAll = checkedCount === max_length;
      this.subject_isIndeterminate = checkedCount > 0 && checkedCount < max_length;
    },
  }
}
</script>

<style scoped lang="scss">
.el-checkbox {
  width: 98rem;
}

.el-checkbox-group .el-checkbox {
  margin-top: 20rem;
}

.el-upload {
  display: block;
}

.el-button--primary {
  background-color: #326FFF;
  border-color: #326FFF;
}

.el-button--primary.is-disabled {
  background-color: #ccc;
  border-color: #ccc;
}

.el-button:focus, .el-button:hover {
  //color: #326FFF;
}

.el-button--primary.is-disabled:hover {
  color: #fff;
}

.body {
  height: 100%;
  background-color: #f7f7f7 !important;

  .main {
    background-color: #fff;

    .wrap {
      padding: 32rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1500rem;
      min-width: 1170rem;
    }

    .card {
      background-color: #F6F9FF;
      padding: 12rem 35rem 35rem 30rem;
      width: 300rem;
      margin-left: 30rem;
      border-radius: 4rem;

      &:first-child {
        margin-left: 0;
      }

      .title {
        color: #1E3AA2;
        font-size: 16rem;
        font-weight: 600;
      }

      .box {
        display: flex;
        justify-content: center;
        margin-top: 50rem;

        .btn {
          width: 88rem;
          height: 40rem;
          color: #326FFF;
          border: 1rem solid #326FFF;
          background-color: transparent;
          border-radius: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          p {
            margin-left: 4rem;
            font-size: 14rem;
          }
        }

        .btn-1 {
          background: #326FFF;
          color: #fff;
        }
      }
    }
  }
}

.t-btn {
  padding: 0 16rem;
  background-color: #326FFF;
  height: 32rem;
  line-height: 32rem;
  display: flex;
  align-items: center;
  border-radius: 4rem;
  cursor: pointer;

  p {
    color: #fff;
    font-weight: 500;
  }

  i {
    margin-right: 4rem;
    color: #fff;
  }
}

.p1 {
  color: #333;
  font-size: 16rem;
  font-weight: 600;
}

.p2 {
  color: #666;
  margin-top: 10rem;
}

.p3 {
  color: #333;
  font-size: 16rem;
  font-weight: 500;
}

.p4 {
  font-size: 14rem;
  color: #666;
  margin-top: 12rem;
  padding-left: 12rem;
  position: relative;

  &:before {
    content: '';
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: #1C4EFD;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.dia-top {
  background: #F6F9FF;
  border-radius: 4rem;
  padding: 20rem 24rem;

  .box {
    margin-top: 20rem;
    display: flex;
  }
}

.dia-main {
  margin-top: 24rem;
  background: #F6F9FF;
  border-radius: 4rem;
  padding: 20rem 24rem;
}

.form-item {
  margin-top: 24rem;
  display: flex;
  align-items: center;
  height: 32rem;

  span {
    width: 120rem;
    font-weight: bold;
    font-size: 16rem;
    color: #333;
  }

  .el-button {
    background-color: #fff;
    border-color: #DBDDE4;
    color: #ccc;
    padding-top: 0;
    padding-bottom: 0;
    height: 32rem;
    line-height: 32rem;
    font-size: 14rem;
  }
}

.select-box {
  display: flex;
  align-items: center;
}

.box-item {
  display: flex;
  align-items: center;
  margin-right: 40rem;

  span {
    color: #333;
    font-size: 16rem;
  }
}

.w-160 {
  width: 160rem;
  margin-left: 20rem;
}

.main-box {
  border: 1rem solid #DBDDE4;
  display: flex;
  margin-top: 30rem;

  .left {
    padding: 24rem;
    flex: 1;
    max-height: 465rem;
    overflow-y: scroll;
  }

  .right {
    width: 260rem;
    min-width: 260rem;
    padding: 24rem;
    border-left: 1rem solid #DBDDE4;
    max-height: 465rem;
    overflow-y: scroll;
  }
}
</style>
